import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { styles } from "../../utils"
// import logo from "../../images/azalea-icon-round.svg"
import logo from "../../images/azalea-logo.svg"
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa"

const year = () => new Date().getFullYear()

export default class Footer extends Component {
  state = {
    social: [
      {
        id: 1,
        text: `Facebook`,
        path: `https://www.facebook.com/AzaleaBarKitchen/`,
      },
      {
        id: 2,
        text: `Twitter`,
        path: `https://twitter.com/azaleaBnK/`,
      },
      {
        id: 3,
        text: `Instagram`,
        path: `https://www.instagram.com/azaleabarkitchen/`,
      },
    ],
  }
  render() {
    return (
      <FooterWrapper>
        <LogoAndHours>
          <Logo
            alt="The Azalea Bar & Kitchen icon, which depicts a yellow azalea"
            src={logo}
          />
          <Hours>
            <span style={{ gridColumn: `1/3` }}>
              <strong>Hours</strong>
            </span>
            <span>Wed - Sat</span>
            <span>3pm to 9pm</span>
            <span>Sat - Sun</span>
            <span>8am to 2pm</span>
          </Hours>
        </LogoAndHours>
        <Links>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/menu/">Menu</Link>
          </li>
          <li>
            <Link to="/patio/">Patio</Link>
          </li>
          <li>
            <Link to="/about/">About</Link>
          </li>
        </Links>
        <Address>
          <span>
            <FaPhoneAlt />
            <a href="tel:8287745969">(828) 774-5969</a>
          </span>
          <span>
            <FaMapMarkerAlt />
            <a href="https://maps.app.goo.gl/EUtcyNkJcRnqnMmk7">
              5 Caledonia Rd, Asheville, NC 28803
            </a>
          </span>
        </Address>
        <Copyright>&copy; {`${year()}`} azalea bar & kitchen</Copyright>
      </FooterWrapper>
    )
  }
}

const FooterWrapper = styled.footer`
  --y-gap: 1rem;
  --width: 55ch;
  background-color: ${styles.colors.primaryColor};
  color: ${styles.colors.mainWhite};
  padding: 2rem 0;
  display: grid;
  gap: var(--y-gap);
  grid-template: repeat(5, auto) / 1fr;
  place-items: center;

  & > * {
    width: 55ch;
    max-width: 90vw;
  }
`

const LogoAndHours = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 4rem;
  row-gap: 1rem;
  padding-bottom: var(--y-gap);
  border-bottom: 1px dotted ${styles.colors.primaryAlt({ l: `50%` })};
`

const Logo = styled.img`
  height: 112px;
  width: auto;
`

const Hours = styled.div`
  display: grid;
  grid-template: repeat(3, max-content) / 1fr 1fr;
  gap: 0;
  // color: ${styles.colors.mainWhite};
  text-transform: uppercase;
`

const Links = styled.menu`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 0 var(--y-gap) 0;
  border-bottom: 1px dotted ${styles.colors.primaryAlt({ l: `50%` })};

  li {
    list-style: none;
    padding: 0 0.75rem;

    &:not(:first-child) {
      border-left: 1px dotted ${styles.colors.primaryAlt({ l: `50%` })};
    }

    a {
      --clr: ${styles.colors.mainWhite};
      border-bottom: 2px dotted var(--border-b-clr);
      color: var(--clr);
      letter-spacing: 2px;
      font-weight: 700;
      text-transform: uppercase;

      &:hover {
        --clr: ${styles.colors.secondaryColor};
      }
    }

    @media (min-width: 576px) {
      padding: 0 2rem;
    }
  }
`

const Address = styled.address`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  align-items: center;
  justify-content: center;
  color: ${styles.colors.mainWhite};
  place-items: center;
  padding-bottom: var(--y-gap);
  border-bottom: 1px dotted ${styles.colors.primaryAlt({ l: `50%` })};

  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  a {
    --border-b-clr: transparent;
    border-bottom: 2px dotted var(--border-b-clr);
    color: ${styles.colors.secondaryColor};

    &:hover {
      --border-b-clr: ${styles.colors.secondaryColor};
    }
  }
`

const Copyright = styled.span`
  font-size: 0.85rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
`
