import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import Navbar from "../components/globals/navbar/Navbar"
import Footer from "../components/globals/Footer"
import Newsletter from "../components/globals/Newsletter"
import { styles } from "../utils"
import { Helmet } from "react-helmet"

// import "./bootstrap.min.css"

const Layout = ({ children, ...props }) => {
  return (
    <React.Fragment>
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&family=Courgette&display=swap');
        </style>
      </Helmet>
      <GlobalStyle />
      <Navbar {...props.header} />
      {children}
      <Newsletter />
      <Footer />
    </React.Fragment>
  )
}

const GlobalStyle = createGlobalStyle`

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }

  body {
    background-color: ${styles.colors.mainWhite};
    color: ${styles.colors.mainBlack};
    margin: 0;
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  address {
    font-style: normal;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5 {
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  p {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  h1 {
    font-family: 'Courgette', cursive;
    text-wrap: balance;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
