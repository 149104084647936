const config = {
  breakpoint: `768px`,
  links: [
    {
      path: "/",
      name: "Home",
    },
    {
      path: "/menu/",
      name: "Menu",
    },
    {
      path: "/patio/",
      name: "Patio",
    },
    {
      path: "/about/",
      name: "About",
    },
  ],
}

export default config
