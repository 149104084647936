import React from "react"
import styled from "styled-components"
import { styles } from "../utils"

function BannerCenter({
  bannerProps,
  title,
  titleProps,
  subtitle,
  subtitleProps,
  headerProps,
  children,
}) {
  return (
    <DefaultBanner {...bannerProps}>
      <header {...headerProps}>
        <h1 className="title" {...titleProps}>
          {title}
        </h1>
        <br />
        <span className="subtitle" {...subtitleProps}>
          {subtitle}
        </span>
      </header>
      {children}
    </DefaultBanner>
  )
}
function BannerLeft({ title, subtitle, children }) {
  return (
    <LeftBanner>
      <h1 className="title">{title}</h1>
      <br />
      <span className="subtitle">{subtitle}</span>
      {children}
    </LeftBanner>
  )
}

const DefaultBanner = styled.article`
  width: 90vw;
  max-width: 65ch;

  header {
    text-align: center;
    text-wrap: balance;
  }

  .title {
    ${styles.textBannerTitle};
    font-weight: 500;
    color: ${styles.colors.mainWhite};
    margin-top: 2rem;
    margin-bottom: 0.2rem;
  }

  .subtitle {
    ${styles.textBannerSubTitle};
    color: ${styles.colors.mainWhite};
    ${styles.letterSpacing({ spacing: "0.15rem" })};
    font-weight: 600;
    text-transform: uppercase;
  }

  p {
    font-size: ${props => props.pFontSize || `1.75rem`};
  }

  hr {
    background: ${styles.colors.mainYellow};
    height: 0.1em;
    margin: 1.5rem 0;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 4rem;
    }
    h3 {
      font-size: 2rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
`

const LeftBanner = styled(DefaultBanner)`
  text-align: left;
  h1 {
    margin-bottom: 3.5rem !important;
  }
`

BannerCenter.defaultProps = {
  headerProps: {},
  title: "azalea bar & kitchen",
  titleProps: {},
  subtitle: "Delicious Food",
  subtitleProps: {},
}

BannerLeft.defaultProps = {
  title: "azalea bar & kitchen",
  subtitle: "Delicious Food",
}

export { BannerCenter, BannerLeft }
